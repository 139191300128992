import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import SiteMetadata from '../components/site-metadata'
import { Header } from 'semantic-ui-react'
import ArticleList from '../components/article-list'
import PropTypes from 'prop-types'

class RootIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <SiteMetadata pathname={this.props.location.pathname} isIndexPage />
          <div className="wrapper">
            <Header as={'h2'} dividing>
              Recent articles
            </Header>
            <ArticleList posts={posts} />
          </div>
        </div>
      </Layout>
    )
  }
}

RootIndex.propTypes = {
  location: PropTypes.any,
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
